import { createRef, lazy, Component, Fragment } from "react";
import "./form.css";
import { Form } from "react-bootstrap";
import Popup from "../Generic/Popups/Popup";
import path from "path";
import "react-quill/dist/quill.snow.css";
import ImageUploader from "./ImageUploaderForRichText/ImageuploaderForRichtext";
import VideoUploader from "./ImageUploaderForRichText/VideouploaderForRichtext";
import { showPopup, closePopup } from "../../helpers/dispatcher";
import CampaignService from "../../services/CampaignService";
import { Quill } from "react-quill";
const ReactQuill = lazy(() => import("react-quill"));
const LoaderSpinner = lazy(() => import("../Generic/LoaderSpinner"));

Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/videoUploader", VideoUploader);

export class BsInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maxLength: props.maxLength ? props.maxLength : 100,
            showLimit: props.showLimit ? true : false,
        };
    }
    render() {
        let errClass = "";
        let errMessage = null;
        let value = this.props.value;

        if (this.props.errors && this.props.errors[this.props.name]) {
            errClass = "is-invalid";
            errMessage = (
                <div className="invalid-feedback">
                    {this.props.errors[this.props.name]}
                </div>
            );
        }

        if (
            this.state.showLimit &&
            value &&
            value.length > this.state.maxLength
        ) {
            value = value.substr(0, this.state.maxLength);
            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: value,
                },
            });
        }

        let label = (
            <label for="exampleInputorg_name">
                {this.props.label}
                {this.props.required ? (
                    <span className="required"> *</span>
                ) : (
                    ""
                )}
            </label>
        );
        if (this.props.label2) {
            label = (
                <div className="label-group">
                    <label for={"exampleInput" + this.props.name}>
                        {this.props.label}
                        {this.props.required ? (
                            <span className="required"> *</span>
                        ) : (
                            ""
                        )}
                    </label>
                    <label className="grey">{this.props.label2}</label>
                </div>
            );
        }

        return (
            <div className={"form-group " + this.props.className}>
                {label}
                <input
                    readOnly={this.props.readOnly || false}
                    autoComplete="new-password"
                    onBlur={
                        this.props.onBlur
                            ? this.props.onBlur
                            : this.props.onChange
                    }
                    onChange={this.props.onChange}
                    value={this.props.value}
                    name={this.props.name}
                    type={this.props.type ? this.props.type : "text"}
                    onFocus={(ev) => {
                        if (ev.target.autocomplete) {
                            ev.target.autocomplete = "whatever";
                        }
                        ev.preventDefault();
                    }}
                    className={"form-control " + errClass}
                    placeholder={this.props.placeholder}
                    min={this.props.min || ""}
                    max={this.props.max || ""}
                    pattern="\d{2}/\d{2}/\d{4}"
                />
                {this.state.showLimit ? (
                    <div className="label-group">
                        <label>&nbsp;</label>
                        {(() => {
                            let labelClass = {
                                className: "grey",
                            };
                            if (
                                value &&
                                value.length > 0.7 * this.state.maxLength
                            ) {
                                labelClass.style = {
                                    color: "#F00",
                                    paddingTop: "10px",
                                };
                            }

                            return (
                                <label {...labelClass}>
                                    {value
                                        ? this.state.maxLength - value.length
                                        : this.state.maxLength}{" "}
                                    Characters Left
                                </label>
                            );
                        })()}
                    </div>
                ) : (
                    ""
                )}

                {errMessage}
            </div>
        );
    }
}

export class BsArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maxLength: props.maxLength ? props.maxLength : 500,
        };
    }
    render() {
        let errClass = "";
        let value = this.props.value;
        let errMessage = null;

        if (this.props.errors && this.props.errors[this.props.name]) {
            errClass = "is-invalid";
            errMessage = (
                <div className="invalid-feedback">
                    {this.props.errors[this.props.name]}
                </div>
            );
        }
        if (value && value.length > this.state.maxLength) {
            value = value.substr(0, this.state.maxLength);
            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: value,
                },
            });
        }

        let label = (
            <label for="exampleInputorg_name">
                {this.props.label}
                {this.props.required ? (
                    <span className="required"> *</span>
                ) : (
                    ""
                )}
            </label>
        );
        if (this.props.label2) {
            label = (
                <div className="label-group">
                    <label for={"exampleInput" + this.props.name}>
                        {this.props.label}
                        {this.props.required ? (
                            <span className="required"> *</span>
                        ) : (
                            ""
                        )}
                    </label>
                    <label className="grey">{this.props.label2}</label>
                </div>
            );
        }
        return (
            <div className={"form-group " + this.props.className}>
                {label}
                <textarea
                    rows={this.props.rows ? this.props.rows : 5}
                    cols={this.props.cols ? this.props.cols : 5}
                    onChange={this.props.onChange}
                    value={value}
                    name={this.props.name}
                    type="text"
                    className={"form-control " + errClass}
                    placeholder={this.props.placeholder}
                />
                <div className="label-group">
                    <label>&nbsp;</label>
                    {(() => {
                        let labelClass = {
                            className: "grey",
                        };
                        if (
                            value &&
                            value.length > 0.7 * this.state.maxLength
                        ) {
                            labelClass.style = {
                                color: "#F00",
                                paddingTop: "10px",
                            };
                        }

                        return (
                            <label {...labelClass}>
                                {value
                                    ? this.state.maxLength - value.length
                                    : this.state.maxLength}{" "}
                                Characters Left
                            </label>
                        );
                    })()}
                </div>
                {errMessage}
            </div>
        );
    }
}

export class RadioGroup extends Component {
    render() {
        let errClass = "";
        let errMessage = null;

        if (this.props.errors && this.props.errors[this.props.name]) {
            errClass = "is-invalid";
            errMessage = (
                <div className="invalid-feedback" style={{ display: "block" }}>
                    {this.props.errors[this.props.name]}
                </div>
            );
        }

        return (
            <div
                className={
                    "form-group " +
                    (this.props.className ? this.props.className : "") +
                    " " +
                    errClass
                }
            >
                {this.props.values.map((item, idx) => {
                    let labelClass = item.class ? item.class : "";
                    return (
                        <label className={labelClass} key={"radio-" + idx}>
                            <input
                                onChange={this.props.onChange}
                                type="radio"
                                checked={this.props.value === item.value}
                                name={this.props.name}
                                value={item.value}
                                disabled={item.disabled}
                            />
                            &nbsp;{item.title}
                        </label>
                    );
                })}
                {errMessage}
            </div>
        );
    }
}

export class FileUploader extends Component {
    constructor(props) {
        super(props);
        this.input = createRef();
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            preview: null,
            fileType: "",
        };
    }

    handleClick(event) {
        event.stopPropagation();
        this.input.current.click();
    }

    handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const fileType = file.type.startsWith("image/") ? "image" : "video";
        const previewURL = URL.createObjectURL(file);

        this.setState({ preview: previewURL, fileType });
        this.props.onChange(event);
    };

    clearFile = () => {
        this.setState({ preview: null, fileType: "" });
        this.props.onChange({ target: { name: this.props.name, files: [] } });
    };

    render() {
        let errClass = "";
        let errMessage = null;
        if (this.props.errors && this.props.errors[this.props.name]) {
            errClass = "is-invalid";
            errMessage = (
                <div className="form-group is-invalid">
                    <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                    >
                        {this.props.errors[this.props.name]}
                    </div>
                </div>
            );
        }

        const acceptedFileTypes = "image/*, video/*";

        let condProps = {};
        if (this.props.validators && this.props.validators.types) {
            condProps.accept = this.props.validators.types.join(",");
        } else {
            condProps.accept = acceptedFileTypes;
        }

        return (
            <div className="row">
                <div
                    className="col-md-12 file-upload-section"
                    onClick={this.handleClick}
                >
                    <div className="file-upload-section-title">
                        <div className="title">
                            {this.props.section_title}{" "}
                            {this.props.required && (
                                <span className="required"> *</span>
                            )}
                        </div>
                        <div className="grey">{this.props.section_title2}</div>
                    </div>

                    {!this.state.preview ? (
                        <div className="addFile">
                            <img
                                src="/images/add-upload.png"
                                alt="Upload Icon"
                            />
                            <div className="addFileTitle">
                                {this.props.title}
                            </div>
                            <p>{this.props.desc}</p>
                            <input
                                {...condProps}
                                type="file"
                                style={{ display: "none" }}
                                onChange={this.handleFileChange}
                                name={this.props.name}
                                ref={this.input}
                            />
                        </div>
                    ) : (
                        <div className="file-preview-container">
                            <button
                                style={{
                                    background: "transparent",
                                    border: "none",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                    color: "#da4c76",
                                }}
                                className="clear-btn"
                                onClick={this.clearFile}
                            >
                                X
                            </button>
                            {this.state.fileType === "image" ? (
                                <img
                                    src={this.state.preview}
                                    alt="Preview"
                                    className="file-preview"
                                />
                            ) : (
                                <video controls className="file-preview">
                                    <source
                                        src={this.state.preview}
                                        type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                </video>
                            )}
                        </div>
                    )}

                    {errMessage}
                </div>
            </div>
        );
    }
}
FileUploader.defaultProps = {
    section_title: "Upload Media",
    desc: "Supports all image & video formats (1:1, 4:3, 9:16, etc.)",
    validators: { types: ["image/*", "video/*"] },
};

export class MultiUploader extends Component {
    constructor() {
        super();
        this.state = {
            images: [],
            uploading: false,
            errMessage: null,
        };
        this.input = createRef();
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        event.stopPropagation();
        if (this.props.labelInput && !this.state.documentName) {
            this.setState({
                errors: { documentName: ["Enter the document name"] },
            });
        } else {
            this.input.current.click();
        }
    }

    onInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        let selectedImages = this.props.selected ? this.props.selected : [];
        var self = this;
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12 file-upload-section">
                        <div className="file-upload-section-title">
                            <div className="title">
                                {this.props.section_title}{" "}
                                {this.props.required ? (
                                    <span className="required"> *</span>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="grey">
                                {this.props.section_title2}
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                            }}
                        >
                            {selectedImages.map((item, idx) => {
                                let is_doc = false;

                                if (!item.name) {
                                    item.name = path.basename(item.previewUrl);
                                }

                                if (
                                    item.previewUrl.includes(
                                        "application/pdf"
                                    ) ||
                                    item.previewUrl === "/images/pdf.png"
                                ) {
                                    item.previewUrl = "/images/pdf.png";
                                    is_doc = true;
                                } else if (
                                    item.previewUrl.includes(
                                        "application/" ||
                                            item.previewUrl ===
                                                "/images/doc.png"
                                    )
                                ) {
                                    item.previewUrl = "/images/doc.png";
                                    is_doc = true;
                                }
                                if (item.mime) {
                                    if (item.mime.includes("application/pdf")) {
                                        item.previewUrl = "/images/pdf.png";
                                    }
                                }

                                let filename = null;
                                if (item.name) {
                                    filename = (
                                        <div
                                            className="label label-default"
                                            style={{
                                                color: "#da4c76",
                                                fontSize: "12px",
                                                textAlign: "center",
                                                padding: "5px",
                                            }}
                                        >
                                            {item.name}
                                        </div>
                                    );
                                }
                                let documentName = null;
                                if (item.documentName) {
                                    documentName = (
                                        <div>{item.documentName}</div>
                                    );
                                }
                                return (
                                    <div>
                                        <div
                                            className="addFile"
                                            style={{
                                                marginRight: "10px",
                                                position: "relative",
                                            }}
                                            key={item.imageId}
                                        >
                                            <i
                                                class="fa fa-times-circle-o"
                                                aria-hidden="true"
                                                style={{
                                                    position: "absolute",
                                                    top: "10px",
                                                    right: "10px",
                                                    zIndex: 99,
                                                }}
                                                onClick={() => {
                                                    self.props.deleteMedia(idx);
                                                }}
                                            >
                                                X
                                            </i>

                                            {(() => {
                                                if (item.type === "video/mp4") {
                                                    return (
                                                        <div
                                                            className="videoThumbnail"
                                                            style={{
                                                                display: "flex",
                                                                flexDirection:
                                                                    "row",
                                                                justifyContent:
                                                                    "space-around",
                                                            }}
                                                        >
                                                            {item.thumbnail}
                                                        </div>
                                                    );
                                                } else {
                                                    if (!is_doc)
                                                        return (
                                                            <div
                                                                className="image"
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    flexDirection:
                                                                        "row",
                                                                    justifyContent:
                                                                        "space-around",
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        item.previewUrl
                                                                    }
                                                                    alt=""
                                                                    style={{
                                                                        maxWidth:
                                                                            "100%",
                                                                        maxHeight:
                                                                            "80%",
                                                                    }}
                                                                />
                                                            </div>
                                                        );
                                                    else
                                                        return (
                                                            <img
                                                                src={
                                                                    item.previewUrl
                                                                }
                                                                alt=""
                                                                style={{
                                                                    maxWidth:
                                                                        "100%",
                                                                    maxHeight:
                                                                        "80%",
                                                                }}
                                                            />
                                                        );
                                                }
                                            })()}
                                            <div
                                                style={{
                                                    wordWrap: "break-word",
                                                }}
                                            >
                                                {filename}
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                wordWrap: "break-word",
                                                fontSize: "12px",
                                            }}
                                        >
                                            {documentName}
                                        </div>
                                    </div>
                                );
                            })}
                            <div>
                                {this.props.labelInput &&
                                !this.state.uploading ? (
                                    <div className="document-name">
                                        <BsInput
                                            label="Document Name"
                                            type="text"
                                            name="documentName"
                                            value={this.state.categoryName}
                                            onChange={this.onInputChange}
                                            errors={this.state.errors}
                                            className="col-md-10 document-name"
                                        />
                                    </div>
                                ) : null}
                                <div
                                    className="addFile"
                                    onClick={this.handleClick}
                                >
                                    {(() => {
                                        if (this.state.uploading) {
                                            return (
                                                <div
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: "12px",
                                                        textAlign: "center",
                                                        color: "#da4c76",
                                                    }}
                                                >
                                                    Uploading...
                                                    <br />
                                                    {(() => {
                                                        if (
                                                            this.props
                                                                .showPercentage
                                                        )
                                                            return (
                                                                <div
                                                                    style={{
                                                                        color: "#da4c76",
                                                                        fontSize:
                                                                            "16px",
                                                                        fontWeight:
                                                                            "bold",
                                                                    }}
                                                                >
                                                                    {
                                                                        this
                                                                            .props
                                                                            .percentage
                                                                    }
                                                                    %
                                                                </div>
                                                            );
                                                        return (
                                                            <img src="/images/loader.gif" />
                                                        );
                                                    })()}
                                                </div>
                                            );
                                        }
                                        let condProps = {};
                                        if (
                                            this.props.validators &&
                                            this.props.validators.types
                                        ) {
                                            condProps.accept =
                                                this.props.validators.types.join(
                                                    ","
                                                );
                                        }
                                        return (
                                            <>
                                                <Fragment>
                                                    <div
                                                        style={{
                                                            color: "#da4c76",
                                                            display: "flex",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="50"
                                                            height="50"
                                                            fill="currentColor"
                                                            class="bi bi-file-earmark-arrow-up-fill"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM6.354 9.854a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 8.707V12.5a.5.5 0 0 1-1 0V8.707L6.354 9.854z" />
                                                        </svg>
                                                    </div>

                                                    <div className="addFileTitle">
                                                        {this.props.title}
                                                    </div>
                                                    <p>{this.props.desc}</p>
                                                    <input
                                                        {...condProps}
                                                        documentName={
                                                            this.state
                                                                .documentName
                                                        }
                                                        type="file"
                                                        style={{
                                                            display: "none",
                                                        }}
                                                        onChange={(ev) => {
                                                            if (
                                                                ev.target.files
                                                                    .length > 0
                                                            ) {
                                                                const sizeInMb =
                                                                    parseInt(
                                                                        ev
                                                                            .target
                                                                            .files[0]
                                                                            .size
                                                                    ) /
                                                                    (1024 *
                                                                        1024);
                                                                const maxSize =
                                                                    this.props
                                                                        .validators
                                                                        ? this
                                                                              .props
                                                                              .validators
                                                                              .size
                                                                            ? this
                                                                                  .props
                                                                                  .validators
                                                                                  .size
                                                                            : 5
                                                                        : 5;

                                                                if (
                                                                    maxSize <
                                                                    sizeInMb
                                                                ) {
                                                                    this.setState(
                                                                        {
                                                                            errMessage: `File size should be less than ${maxSize}MB`,
                                                                        },
                                                                        () => {
                                                                            setTimeout(
                                                                                () => {
                                                                                    this.setState(
                                                                                        {
                                                                                            errMessage:
                                                                                                null,
                                                                                        }
                                                                                    );
                                                                                },
                                                                                4000
                                                                            );
                                                                        }
                                                                    );
                                                                    return;
                                                                }
                                                                const typesSupported =
                                                                    this.props
                                                                        .validators
                                                                        ? this
                                                                              .props
                                                                              .validators
                                                                              .types
                                                                            ? this
                                                                                  .props
                                                                                  .validators
                                                                                  .types
                                                                            : []
                                                                        : [];
                                                                if (
                                                                    !typesSupported.includes(
                                                                        ev
                                                                            .target
                                                                            .files[0]
                                                                            .type
                                                                    )
                                                                ) {
                                                                    this.setState(
                                                                        {
                                                                            errMessage: `File type not supported`,
                                                                        },
                                                                        () => {
                                                                            setTimeout(
                                                                                () => {
                                                                                    this.setState(
                                                                                        {
                                                                                            errMessage:
                                                                                                null,
                                                                                        }
                                                                                    );
                                                                                },
                                                                                4000
                                                                            );
                                                                        }
                                                                    );
                                                                    return;
                                                                }
                                                                this.setState(
                                                                    {
                                                                        uploading: true,
                                                                        errors: {},
                                                                        documentName:
                                                                            "",
                                                                    },
                                                                    this.props.multipleImageHandler(
                                                                        ev,
                                                                        () => {
                                                                            return this.setState(
                                                                                {
                                                                                    uploading: false,
                                                                                    errors: {},
                                                                                    documentName:
                                                                                        "",
                                                                                }
                                                                            );
                                                                        }
                                                                    )
                                                                );
                                                            }
                                                        }}
                                                        name={this.props.name}
                                                        ref={this.input}
                                                    />
                                                </Fragment>
                                            </>
                                        );
                                    })()}
                                </div>
                            </div>

                            {(() => {
                                if (this.state.errMessage) {
                                    return (
                                        <Popup
                                            showWindow={
                                                this.state.errMessage
                                                    ? true
                                                    : false
                                            }
                                            showCloseButton={false}
                                            title={this.state.errMessage}
                                            buttonContainerClass="right"
                                            buttons={[
                                                {
                                                    class: "btn btn-primary",
                                                    text: "Ok",
                                                    onClick: (ev) => {
                                                        this.setState({
                                                            errMessage: null,
                                                        });
                                                    },
                                                },
                                            ]}
                                        >
                                            <div
                                                style={{ minWidth: "300px" }}
                                            ></div>
                                        </Popup>
                                    );
                                }
                                return null;
                            })()}
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export class SmallFileUploader extends Component {
    constructor() {
        super();
        this.state = { selected: false };
        this.input = createRef();
        this.handleClick = this.handleClick.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    handleClick(event) {
        event.stopPropagation();
        this.input.current.click();
    }

    onChange(event) {
        this.props.onChange(event);
        var self = this;
        let reader = new FileReader();
        reader.onload = (e) => {
            //docs.push({imageId:response.data.data.id,previewUrl:e.target.result})
            self.setState({ image: e.target.result, selected: true });
        };
        reader.readAsDataURL(event.target.files[0]);
    }

    render() {
        let errClass = "";
        let errMessage = "";

        if (this.props.errors && this.props.errors[this.props.name]) {
            errClass = "is-invalid";
            errMessage = (
                <div className="invalid-feedback">
                    {this.props.errors[this.props.name]}
                </div>
            );
        }

        let label = (
            <label for="exampleInputorg_name">
                {this.props.label}
                {this.props.required ? (
                    <span className="required"> *</span>
                ) : (
                    ""
                )}
            </label>
        );
        let condProps = {};
        if (this.props.validators && this.props.validators.types) {
            condProps.accept = this.props.validators.types.join(",");
        }

        return (
            <div className={"form-group " + this.props.className}>
                {label}
                <div className="smallFileUploader" onClick={this.handleClick}>
                    <img
                        src={
                            this.state.selected
                                ? this.state.image
                                : "/images/upload-small.png"
                        }
                        style={{ maxWidth: "30px" }}
                    />
                    <div>
                        {this.state.selected ? "Change Image" : "Add Picture"}
                    </div>
                </div>
                {errMessage}
                <input
                    {...condProps}
                    type="file"
                    style={{ display: "none" }}
                    onChange={this.onChange}
                    name={this.props.name}
                    ref={this.input}
                />
            </div>
        );
    }
}

export class SelectComponent extends Component {
    render() {
        let myProps = {
            as: "select",
            name: this.props.name,
            onChange: this.props.onChange,
            defaultValue: this.props.defaultValue,
            value: this.props.value,
            style: this.props.style ? this.props.style : null,
        };

        let errMessage = null;

        if (this.props.errors && this.props.errors[this.props.name]) {
            myProps.isInvalid = true;
            errMessage = (
                <Form.Control.Feedback type="invalid">
                    {this.props.errors[this.props.name]}
                </Form.Control.Feedback>
            );
        }

        return (
            <div className="form-group">
                <div className="label-group">
                    <label for="exampleInputorg_type">
                        {this.props.label}
                        {this.props.required ? (
                            <span className="required"> *</span>
                        ) : (
                            ""
                        )}
                    </label>
                </div>
                <Form.Control {...myProps}>{this.props.children}</Form.Control>
                {errMessage}
            </div>
        );
    }
}

export class BsRichText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maxLength: props.maxLength ? props.maxLength : 500,
            className: props.className,
            quillRef: props.quillRef,
            S3RequestUrl: props.S3RequestUrl,
            value: props.value || "",
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        console.log(this.props.value, "RichTExt");
        return (
            this.props.quillRef !== nextProps.quillRef ||
            this.props.forceRefresh !== nextProps.forceRefresh ||
            nextProps.value !== this.props.value
        );
    }

    modules = {
        toolbar: [
            [
                { size: [] },
                "bold",
                "italic",
                "image",
                "video",
                "underline",
                "strike",
                "blockquote",
                { list: "ordered" },
                { list: "bullet" },
                "link",
            ],
        ],
        videoUploader: {
            upload: async (file) => {
                showPopup({
                    title: "Uploading",
                    content: <LoaderSpinner />,
                    onClose: () => {},
                });
                return new Promise((resolve, reject) => {
                    CampaignService.getS3Url(
                        { fileName: file.name, mimeType: file.type },
                        this.state.S3RequestUrl
                    ).then((data) => {
                        var xhr = new XMLHttpRequest();
                        xhr.open("PUT", data.data.postUrl, true);
                        xhr.setRequestHeader("Content-Type", file.type);
                        xhr.onload = () => {
                            if (xhr.status === 200) {
                                closePopup();
                                return resolve(data.data.getUrl);
                            } else {
                                reject(null);
                            }
                        };

                        xhr.onerror = () => {
                            reject(null);
                        };

                        xhr.upload.onprogress = (event) => {};
                        xhr.send(file);
                    });
                });
            },
        },
        imageUploader: {
            upload: async (file) => {
                showPopup({
                    title: "Uploading",
                    content: <LoaderSpinner />,
                    onClose: () => {},
                });
                return new Promise((resolve, reject) => {
                    CampaignService.getS3Url(
                        { fileName: file.name, mimeType: file.type },
                        this.state.S3RequestUrl
                    ).then((data) => {
                        var xhr = new XMLHttpRequest();
                        xhr.open("PUT", data.data.postUrl, true);
                        xhr.setRequestHeader("Content-Type", file.type);
                        xhr.onload = () => {
                            if (xhr.status === 200) {
                                closePopup();
                                return resolve(data.data.getUrl);
                            } else {
                                reject(null);
                            }
                        };

                        xhr.onerror = () => {
                            reject(null);
                        };

                        xhr.upload.onprogress = (event) => {};
                        xhr.send(file);
                    });
                });
            },
        },
    };

    formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "Video",
        "imageBlot",
        "videoBlot",
    ];

    handleChange = (value) => {
        this.setState({ value });
        try {
            if (this.props.onChange) {
                this.props.onChange(value);
            }
        } catch (error) {
            if (this.props.onChange) {
                this.props.onChange({
                    target: {
                        name: this.props.name,
                        value: value,
                    },
                });
            }
        }
    };

    render() {
        let label = (
            <label htmlFor="exampleInputorg_name">
                {this.props.label}
                {this.props.required ? (
                    <span className="required">*</span>
                ) : null}
            </label>
        );
        if (this.props.label2) {
            label = (
                <div className="label-group">
                    <label htmlFor="exampleInputorg_name">
                        {this.props.label}
                        {this.props.required ? (
                            <span className="required">*</span>
                        ) : null}
                    </label>
                    <label className="grey">{this.props.label2}</label>
                </div>
            );
        }

        return (
            <div
                className={"form-group " + this.state.className}
                style={{ minHeight: "400px" }}
            >
                {label}
                <ReactQuill
                    theme="snow"
                    modules={this.props.modules || this.modules}
                    formats={this.props.formats || this.formats}
                    value={this.state.value}
                    placeholder={this.props.placeholder}
                    style={{ height: "320px" }}
                    ref={this.state.quillRef}
                    onChange={this.handleChange}
                >
                    <div className="editing-area" />
                </ReactQuill>
            </div>
        );
    }
}
